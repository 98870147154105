import React, { useEffect } from 'react';
import LoginWithFacebook from './login/LoginWithFacebook';
import './App.css';
import { useState } from 'react';
import { authWithFacebookToken } from './services/cr8tr-service';
import Confetti from './Confetti';
import logo from './static/logo.png'
import InstagramAccountSelector from './InstagramAccountSelector'

function App() {
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [cr8trAccessToken, setCr8trAccessToken] = useState<string | undefined>();
  const [isAuthingCr8tr, setIsAuthingCr8tr] = useState(false);
  const [isFullyAuthed, setIsFullyAuthed] = useState(false);

  const scope = [
    "email",
    "public_profile",
    "business_management",
    "instagram_manage_insights",
    "pages_show_list",
    "instagram_basic",
    "instagram_manage_comments",
    "instagram_content_publish",
  ];

  return (
    <div className="App">
      <header className="App-header bg-hellocreator-white">
        <img src={logo} style={{maxWidth: 'min(80%, 161px)'}}/>
        <LoginWithFacebook
          scope={scope.join(',')}
          onLoginSuccess={(token: string) => {
            setAccessToken(token);
            setIsAuthingCr8tr(true);
            authWithFacebookToken(token, (cr8trToken) => {
              setIsAuthingCr8tr(false);
              setIsFullyAuthed(!!cr8trToken);
              if (!cr8trToken) {
                setAccessToken(undefined);
              } else {
                setCr8trAccessToken(cr8trToken)
              }
            });
          }}
          onLoginFailure={(error: any) => {
            console.log(`Failed to login: ${JSON.stringify(error)}`);
            alert(`An error occurred: ${JSON.stringify(error)}`);
            setAccessToken(undefined);
          }}
          render={(props: any) => {
            if (isFullyAuthed) {
              return (
                <InstagramAccountSelector
                token={cr8trAccessToken!} />
              );
            } else if (isAuthingCr8tr) {
              return (
                <></>
              );
            } else {
              return (
                <div>
                  <p className='flex flex-row justify-center app-name-base font-pp-mori'><div className='text-hellocreator-dark-grey'>coach</div><div className="text-hellocreator-primary-500">ai</div></p>
                  <br/>
                  <p className='p-base font-pp-neue-montreal max-w-prose text-hellocreator-grey px-8'>In order to use Coach, you'll need to connect your Instagram and Facebook accounts.</p>
                  <br></br>
                  <button onClick={props.onClick} className='button-base button-login font-pp-neue-montreal bg-hellocreator-primary-500 rounded-lg'>Start</button>
                </div>
              );
            }
          }} />
      </header>
    </div>
  );
}

export default App;
