import React, { useEffect, useState } from 'react';

declare global {
  interface Window {
    FB: any,
    fbAsyncInit: any,
  }
}

export interface ReactFacebookLoginInfo {
  id: string;
  userID: string;
  accessToken: string;
  name?: string | undefined;
  email?: string | undefined;
  picture?:
  | {
    data: {
      height?: number | undefined;
      is_silhouette?: boolean | undefined;
      url?: string | undefined;
      width?: number | undefined;
    };
  }
  | undefined;
}

export interface FacebookLoginProps {
  language?: string;
  onLoginSuccess: (response: string) => void;
  onLoginFailure: (error: any) => void;
  isDisabled?: boolean;
  render: (props: { onClick: (e: any) => void }) => JSX.Element;
  scope?: string;
  fields?: string;
}

const LoginWithFacebook = (
  args: FacebookLoginProps
) => {

  const props = {
    ...args,
    isDisabled: args.isDisabled ?? false,
    language: args.language ?? 'en_US',
    scope: args.scope ?? 'instagram_basic, pages_show_list',
    fields: args.fields ?? 'name,email,picture',
  }

  const appId = process.env.REACT_APP_FB_ID;
  const redirectUri = process.env.REACT_APP_HOST;
  const [isMounted, setIsMounted] = useState(false);
  const [isSdkLoaded, setIsSdkLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [authError, setAuthError] = useState<string | undefined>(undefined);

  const click = (e: any) => {
    // TODO: Check if sdk is loaded
    setIsProcessing(true);

    if (!window.FB) {
      if (props.onLoginFailure) {
        props.onLoginFailure({ status: 'facebookNotLoaded' });
      }
      return;
    }

    console.log('requesting scopes: ' + props.scope)
    const queryParams = {
      'auth_type': 'rerequest',
      'client_id': appId,
      'display': 'page',
      'response_type': 'token',
      'redirect_uri': redirectUri,
      'extras': '{"setup":{"channel":"IG_API_ONBOARDING"}}',
      'scope': props.scope,
      'state': Math.random().toString(),
    };
    const loginUrl = `https://facebook.com/v18.0/dialog/oauth${getParamsFromObject(queryParams)}`;
    window.open(loginUrl, '_self');
  };

  const getParamsFromObject = (params: any) => {
    return '?' + Object.keys(params)
      .map(param => `${param}=${encodeURIComponent(params[param])}`)
      .join('&');
  };

  const responseApi = (authResponse: any) => {
    window.FB.api('/me', { locale: props.language, fields: props.fields }, (me: any) => {
      Object.assign(me, authResponse);
      props.onLoginSuccess(me);
    });
  };

  const setFbAsyncInit = () => {
    const autoload = false;
    window.fbAsyncInit = () => {
      window.FB.init({
        version: `v18.0`,
        appId,
      });
      if (isMounted) {
        setIsSdkLoaded(true);
      }
      if (autoload) {
        window.FB.getLoginStatus(checkLoginAfterRefresh);
      }
    };
  }

  const loadSdkAsynchronously = () => {
    ((d, s, id) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      if (d.getElementById(id)) { return; }
      js = d.createElement(s);
      js.id = id;
      (js as HTMLScriptElement).src = `https://connect.facebook.net/${props.language}/sdk.js`;
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  };

  const checkLoginState = (response: any) => {
    if (isMounted) {
      setIsProcessing(false);
    }
    if (response.authResponse) {
      responseApi(response.authResponse);
    } else {
      props.onLoginFailure({ status: response.status });
    }
  };

  const checkLoginAfterRefresh = (response: any) => {
    if (response.status === 'connected') {
      checkLoginState(response);
    } else {
      window.FB.login((loginResponse: any) => checkLoginState(loginResponse), true);
    }
  };

  useEffect(() => {
    if (!!accessToken) {
      console.log("Calling success callback");
      props.onLoginSuccess(accessToken);
      setAccessToken(undefined);
    }
  }, [accessToken]);

  useEffect(() => {
    if (!!authError) {
      console.log("Calling error callback");
      props.onLoginFailure(authError);
      setAuthError(undefined);
    }
  }, [authError]);

  // one-time effect for loading fb sdk
  useEffect(() => {
    let setMounted = () => {
      setIsMounted(true);
    }
    let setLoaded = () => {
      setIsSdkLoaded(true);
    }
    setMounted();

    if (document.getElementById('facebook-jssdk')) {
      setLoaded();
      return;
    }
    setFbAsyncInit();
    loadSdkAsynchronously();
    let fbRoot = document.getElementById('fb-root');
    if (!fbRoot) {
      fbRoot = document.createElement('div');
      fbRoot.id = 'fb-root';
      document.body.appendChild(fbRoot);
    }
  }, []);

  // monitor for redirect back to us
  useEffect(() => {
    const currentUrl = window.location.href;
    console.log(`current href: ${currentUrl}`);

    // look for access tokens
    if (currentUrl.split('#').length === 2) {
      console.log('Found # in url.');
      const items = currentUrl.split('#')[1].split('&');
      for (var i = 0; i < items.length; i++) {
        const keyValue = items[i].split('=');
        if (keyValue.length !== 2) {
          continue;
        }
        if (keyValue[0] === 'access_token') {
          console.log(`found access token: ${keyValue[1]}`);
          setAccessToken(keyValue[1]);
          break;
        }
      }
    }

    // look for errors
    if (currentUrl.split('?').length === 2) {
      const items = currentUrl.split('?')[1].split('&');
      for (var i = 0; i < items.length; i++) {
        const keyValue = items[i].split('=');
        if (keyValue.length !== 2) {
          continue;
        }
        if (keyValue[0] === 'error_description') {
          console.log(`found error: ${keyValue[1]}`);
          setAuthError(keyValue[1]);
        }
      }
    }
  });

  return props.render({ onClick: click });
};

export default LoginWithFacebook;