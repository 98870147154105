import { InstagramAccountData } from "../InstagramAccountSelector";

const { v4: uuidv4 } = require('uuid');

export const authWithFacebookToken = (token: string, callback: (cr8trAccessToken?: string) => void) => {
    const host = process.env.REACT_APP_BACKEND_HOST;
    const url = `${host}/api/me/auth/meta/`;
    console.log(`Making POST to ${url}`);
    fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            accessToken: token,
            deviceId: uuidv4(),
            deviceName: 'web-preonboarding'
        })
    })
        .then((response) => response.json())
        .then((data) => {
            callback(data?.value?.tokens?.accessToken);
        })
        .catch((error) => {
            console.log(error);
            callback(undefined);
            alert(`Failed to auth with Cr8tr server: ${JSON.stringify(error)}`);
        });
}

export const getInstagramAccounts = (token: string, callback: (data: InstagramAccountData[]) => void) => {
    const host = process.env.REACT_APP_BACKEND_HOST;
    const url = `${host}/api/me/instagram/accounts`;
    console.log(`Making GET to ${url}`);
    fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    })
        .then((response) => response.json())
        .then((data) => {
            callback(data.value.instagramAccounts);
        })
        .catch((error) => {
            console.log(error);
            alert(`Failed to get instagram accounts: ${JSON.stringify(error)}`);
        });
}

export const setInstagramAccounts = (token: string, instagramId: string, callback: (success: boolean) => void) => {
    const host = process.env.REACT_APP_BACKEND_HOST;
    const url = `${host}/api/me/instagram/accounts`;
    console.log(`Making POST to ${url}`);
    fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            instagramIds: [instagramId]
        })
    })
        .then((response) => response.json())
        .then((data) => {
            callback(true);
        })
        .catch((error) => {
            console.log(error);
            callback(false);
            alert(`Failed to select instagram account: ${JSON.stringify(error)}`);
        });
}