

import React, { useEffect } from 'react';
import './App.css';
import { useState } from 'react';
import { getInstagramAccounts, setInstagramAccounts } from './services/cr8tr-service';
import Confetti from './Confetti';
import Spinner from 'react-bootstrap/Spinner';

export interface InstagramAccountData {
  instagramId: string
  instagramUsername: string
  instagramProfilePhotoUrl?: string
  instagramName?: string
}

function InstagramAccountSelector(props: {
  token: string,
}) {

  
  const [isGettingInstagramAccounts, setIsGettingInstagramAccounts] = useState(true);
  const [instagramAccountsData, setInstagramAccountsData] = useState<InstagramAccountData[]>([]);
  const [selectedInstagramId, setSelectedInstagramId] = useState<string | undefined>();
  const [didSelectInstagramAccount, setDidSelectInstagramAccount] = useState(false);

  useEffect(() => {
    setIsGettingInstagramAccounts(true)
    getInstagramAccounts(props.token, (data: InstagramAccountData[]) => {
      setInstagramAccountsData(data)
      // if there is only one account, go ahead and select that one
      if (data.length === 1) {
        setInstagramAccounts(props.token, data[0].instagramId, (success: boolean) => {
          if (success) {
            setDidSelectInstagramAccount(true)
          }
        })
      }
      setIsGettingInstagramAccounts(false);
    });
  }, []);
  
  const setInstagramAccount = (e: any) => {
    if (selectedInstagramId) {
      setInstagramAccounts(props.token, selectedInstagramId, (success: boolean) => {
        if (success) {
          setDidSelectInstagramAccount(true)
        }
      })
    } else {
      alert(`Please select an instagram account first!`)
    }
  }

  const celebration = (
    <div>
      <Confetti />
      <p className='p-base font-pp-neue-montreal max-w-prose text-hellocreator-grey px-8'>You’re all set! You’re connected and Coach is thinking up some ideas just for you. Please wait 15 minutes before using the Coach app.</p>
    </div>
  )

  const picker = (
  <div className='flex-col max-w-[80%] ml-auto mr-auto text-left font-pp-neue-montreal'>
    <div className='text-lg py-3 text-hellocreator-medium-dark-grey'>We found multiple accounts</div>
    <div className='text-base py-3 text-hellocreator-grey'>It looks like you have multiple Instagram accounts, which one would you like Coach to use?</div>
    {instagramAccountsData.map(d => {
      return <div onClick={() => setSelectedInstagramId(d.instagramId)} id={d.instagramId} className={`flex flex-row rounded-[14px] border-[1.5px] my-4 p-[1.25rem] text-hellocreator-medium-dark-grey ${selectedInstagramId === d.instagramId ? 'border-hellocreator-primary-500' : 'border-hellocreator-neutral-300'}`}>
        <div className='grow content-center text-base'>
        <input 
        className='border-0 mr-4 accent-hellocreator-primary-500'
          type="radio"
          id={`radio-${d.instagramUsername}`}
          value=""
          checked={ selectedInstagramId === d.instagramId } 
          onChange={() => setSelectedInstagramId(d.instagramId)} 
      /> 
          {d.instagramUsername}
          </div>
        <img src={d.instagramProfilePhotoUrl} className='rounded-full  size-8'></img>
      </div>
    })}
    <button onClick={setInstagramAccount} className={`text-base rounded-[14px] w-[100%] my-6 py-[1.25rem] ${!!selectedInstagramId ? 'bg-hellocreator-primary-500' : 'disabled bg-hellocreator-neutral-100 text-hellocreator-neutral-300'}`}>Select</button>
  </div>)

  const noInstagramAccountsError = (
    <div className='flex-col max-w-[80%] ml-auto mr-auto text-left font-pp-neue-montreal'>
      <div className='text-lg py-3 text-hellocreator-medium-dark-grey'>We couldn't find any instagram accounts.</div>
      <div className='text-base py-3 text-hellocreator-grey'>Make sure:</div>
      <ol className="list-decimal text-hellocreator-grey text-base">
  <li>You have a Professional Instagram account (Business or Creator account) and</li>
  <li>The instagram account is connected to a page owned by the Facebook account you are using to log in.</li>
</ol>
    </div>
  )


  return <div>
    {
      isGettingInstagramAccounts ? <Spinner animation="border" className=' text-hellocreator-primary-400'/> : (
        didSelectInstagramAccount || instagramAccountsData.length === 1 ? 
        celebration : (
          instagramAccountsData.length === 0 ? noInstagramAccountsError : picker
        )
      )
    }
  </div>
}

export default InstagramAccountSelector;